/* eslint-disable */
export const BUILDVARS = {
  "hashes": {
    "creatures": "58cf94f4b520776624bfe162d5c8a56e",
    "decor": "6b385dc791b0e787673ebbf0d006382e",
    "effects": "c5093c92497b26dd77e0098daada3c52",
    "items": "22bb45e6f7caa89f72ce2aee8b18fdf8",
    "swimming": "1a4540483aad852eba348d47a6550a27",
    "terrain": "b711c873655488ede38aacceb7ef59e1",
    "walls": "0a970dea273a4b462b43cdcb74db6d96",
    "itemsanimations": "5c27eb4ccd431ac58ec345bfa7138fb7",
    "decoranimations": "215226d02d1d80c138dfb7f2ca000da0",
    "terrainanimations": "b4c69315a3b5f41213062881b6b57eb4"
  },
  "version": {
    "dirty": false,
    "raw": "98404ac",
    "hash": "98404ac",
    "distance": null,
    "tag": null,
    "semver": null,
    "suffix": "98404ac",
    "semverString": null
  }
};